
<template>
  <el-drawer :wrapper-closable="wrapperClosable" :close-on-press-escape="closeOnPressEscape" class="drawer" :append-to-body="toBody" :with-header="false" :visible.sync="visible" direction="rtl" :size="width" :show-close="false">
    <div class="drawer-wrapper">
      <div class="drawer-header">
        <h4>{{title}}</h4>
        <i class="el-icon-close" @click="cancel"></i>
      </div>
      <div class="drawer-main">
        <slot></slot>
      </div>
      <div v-if="hasFooter" class="drawer-footer">
        <el-button v-if="showCancel" size="small" class="cancel" @click="cancel">{{cancelStr || '取消'}}</el-button>
        <el-button v-if="showConfirm" size="small" class="confirm" type="primary" @click="confirm">{{confirmStr || '确定'}}</el-button>
      </div>
    </div>
  </el-drawer>
<!--  <transition name="slide" mode="out-in">-->
<!--    -->
<!--  </transition>-->
</template>

<script>
import Popup from 'element-ui/lib/utils/popup'

export default {
  name: 'BaseDrawer',

  mixins: [Popup],
  data () {
    return {}
  },
  props: {
    modal: {
      type: Boolean,
      default: true
    },
    toBody: {
      type: Boolean,
      default: false
    },
    showConfirm: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },

    closeOnPressEscape: {
      type: Boolean,
      default: false
    },
    wrapperClosable: {
      type: Boolean,
      default: false
    },
    beforeClose: Function,

    width: Number,

    title: String,
    confirmStr: String,
    cancelStr: String,
    hasFooter: {
      type: Boolean,
      default: true
    }
  },

  mounted () {
    if (this.visible) {
      this.rendered = true
      this.open()
    }
  },

  watch: {
    visible (val) {
      if (val) {
        this.$emit('open')
      } else {
        this.onClose()
      }
    }
  },

  methods: {
    onClose () {
      if (this.beforeClose) {
        this.beforeClose(this.hide)
      } else {
        this.hide()
      }
    },

    hide () {
      this.$emit('update:visible', false)
      this.$emit('close')
    },

    confirm () {
      this.$emit('confirm')
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped lang="scss">
  .drawer {
    &-wrapper {
      /*position: fixed;*/
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      /*min-width: 300px;*/
      overflow: hidden;
      background: #ffffff;
    }

    &-header {
      position: relative;
      flex: none;
      padding: 15px;
      border-bottom: 1px solid #e0e0e0;

      > h4 {
        margin: 0;
        font-size: 16px;
      }

      .el-icon-close {
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 18px;
        cursor: pointer;
      }
    }

    &-main {
      flex: 1;
      padding: 24px 0 0 24px;
      overflow: auto;
    }

    &-footer {
      flex: none;
      padding: 16px 24px;
      border-top: 1px solid #e0e0e0;
      text-align: right;
      height: 64px;
      .cancel {
        width: 74px;
        margin-left: 12px;
      }
      .confirm {
        width: 74px;
      }
    }
  }
  .slide {
    &-enter-active {
      animation: slide-fade-in 0.3s;
    }

    &-leave-active {
      animation: slide-fade-out 0.3s;
    }
  }

  @keyframes slide-fade-in {
    0% {
      transform: translate3d(20px, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes slide-fade-out {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      transform: translate3d(20px, 0, 0);
      opacity: 0;
    }
  }
</style>
