<template>
  <div class="verify">
    <part-title title="校对数据配置"></part-title>
    <div class="search-box">
      <el-form v-if="queryHandle" :inline="true" :model="formData">
        <el-form-item label="品牌：">
          <el-select size="small" clearable v-model="formData.brandCode" placeholder="请选择品牌" @change="handleBrandChange">
            <el-option
              v-for="item in brandList"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="型号：">
          <el-select size="small" clearable v-model="formData.modelCode" placeholder="请选择型号">
            <el-option
              v-for="item in modelList"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="doSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-box">
      <comp-table
        :table-opts="tableOpts"
        :data="tableData"
        :openHandle="setUpHandle"
        :handle-opts="handleOpts"
        :page="currentPage"
        :total="totalPage"
        :page-size="pageSize"
        :showNumber="true"
        handle-width="80"
        @edit="doEdit"
        @handleSizeChange="sizeChange"
        @handleCurrentChange="pageChange"></comp-table>
    </div>
    <comp-drawer
      :visible.sync="showDrawer"
      :width="500"
      title="设置"
      cancel-str="关闭"
      confirm-str="保存"
      @confirm="doSave"
      @cancel="doClose">
      <div class="config-box">
        <div class="config-title">设置抽检比例</div>
        <el-slider :min="5" v-model="configData.samplingRate" tooltip-class="tooltips1"></el-slider>
        <div class="config-title">设置必检项</div>
        <el-switch
          style="margin-bottom: 8px;"
          v-model="configData.open"
          @change="doChange">
        </el-switch>
        <el-table
          ref="checkbox"
          v-show="configData.open"
          :data="configTable"
          @selection-change="handleSelectionChange"
          style="width: 100%">
          <el-table-column
            type="selection"
            width="46">
          </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50">
          </el-table-column>
          <el-table-column
            label="指标">
            <template slot-scope="scope">
              <span>{{scope.row.module ? scope.row.module + ' - ' : ''}}{{scope.row.subModule ? scope.row.subModule + ' - ' : ''}}{{scope.row.name}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </comp-drawer>
  </div>
</template>

<script>
import PartTitle from '@/components/Part'
import CompTable from '@/components/Table/Table'
import CompDrawer from '@/components/Drawer/Drawer'
import { spotCheckList, spotCheckDetail, spotCheckSave } from '@/service/module/spot-check'
import { getCiedTpl } from '@/service/module/followup'

export default {
  components: {
    PartTitle,
    CompTable,
    CompDrawer
  },
  data () {
    return {
      showDrawer: false,
      configData: {
        samplingRate: 5,
        open: false,
        requireCheckIds: []
      },
      tableOpts: [
        {
          label: '品牌',
          prop: 'brandName'
        },
        {
          label: '型号',
          prop: 'modelName'
        },
        {
          label: '抽检比例',
          prop: 'samplingRate',
          render: (h, params) => {
            return h('span', {}, params.row.samplingRate + '%')
          }
        }
      ],
      handleOpts: {
        edit: {
          type: 'text',
          text: '设置'
        }
      },
      tableData: [],
      configTable: [],
      totalPage: 0, // 总条数
      currentPage: 1, // 当前条数
      pageSize: 20, // 每页条数
      brandList: [],
      modelList: [],
      formData: {
        brandCode: '',
        modelCode: ''
      },
      tplId: null,

      queryHandle: false,
      setUpHandle: false
    }
  },
  mounted () {
    this.getCiedDict()
    this.getList()
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    authList.find(i => {
      if (i === 'data-verify-settings:query') {
        this.queryHandle = true
      }
      if (i === 'data-verify-settings:set-up') {
        this.setUpHandle = true
      }
    })
  },
  methods: {
    toggleChecked (items) {
      this.$nextTick(() => {
        items.map(row => {
          this.$refs.checkbox.toggleRowSelection(this.configTable.find(item => row.paramId === item.paramId), true)
        })
      })
    },
    async getList () {
      try {
        const res = await spotCheckList({
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          brandCode: this.formData.brandCode,
          modelCode: this.formData.modelCode
        })
        console.log('列表：', res)
        if (res && res.code === 200) {
          this.totalPage = res.data.total
          this.tableData = res.data.results
        }
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    async getCiedDict () {
      try {
        const res = await getCiedTpl()
        console.log('品牌型号:', res)
        if (res && res.code === 200) {
          this.brandList = res.data
        }
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    async doSave () {
      try {
        const requireCheckIds = []
        this.configData.requireCheckIds.forEach(i => {
          requireCheckIds.push(i.paramId)
        })

        const res = await spotCheckSave({
          tplId: this.tplId,
          samplingRate: this.configData.samplingRate,
          requireCheckIds
        })
        console.log('res:', res)
        if (res && res.code === 200) {
          this.$message.success('保存成功')
          this.doClose()
          this.doSearch()
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    doSearch () {
      this.getList()
    },
    async doEdit (params) {
      console.log('dsa:', params)
      try {
        this.showDrawer = true
        const res = await spotCheckDetail(params.id)
        if (res && res.code === 200) {
          const { tplId, samplingRate, paramSettings } = res.data
          this.tplId = tplId
          this.configData.samplingRate = samplingRate
          // 设置选中项
          const selectedIndex = []
          this.configTable = paramSettings
          paramSettings.forEach((item, index) => {
            if (item.requireCheck === 1) {
              selectedIndex.push(item)
            }
          })
          if (selectedIndex.length > 0) {
            this.configData.open = true
            this.toggleChecked(selectedIndex)
          }
        }

        console.log('详情：', res)
      } catch (e) {
        this.$message.error(e.message)
      }
      console.log('params:', params)
    },
    doClose () {
      console.log('close')
      this.showDrawer = false
      this.configData = {
        open: false,
        samplingRate: 5,
        requireCheckIds: []
      }
      this.configTable = []
      this.tplId = null
    },
    doChange (val) {
      console.log('val:', val)
      if (!val) {
        this.$refs.checkbox.clearSelection()
      }
    },
    handleBrandChange (val) {
      this.formData.brandCode = val
      this.formData.modelCode = ''
      if (val) {
        const modelData = this.brandList.find(o => o.code === val)
        this.modelList = modelData.models
      } else {
        this.modelList = []
      }
    },
    handleSelectionChange (val) {
      this.configData.requireCheckIds = val
    },
    sizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    pageChange (val) {
      this.currentPage = val
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
.verify {
  padding: 0 20px;
  .search-box {
    display: flex;
    justify-content: right;
  }
  .config-box {
    padding-right: 24px;
    box-sizing: border-box;
    .config-title {
      margin-bottom: 8px;
      color: #606266;
      font-size: 14px;
      line-height: 22px;
    }
    ::v-deep .el-slider__runway {
      margin: 0 0 34px;
    }
    ::v-deep .el-table {
      border: 1px solid #EBEEF5;
      border-bottom: none;
    }
  }
}
::v-deep .el-form-item__label {
    padding-right: 0px;
}
::v-deep .el-form-item {
  margin:0 24px 20px 0;

}
</style>
