<template>
  <section>
    <el-table
      ref="table"
      :data="data"
      :key="randKey"
      style="width: 100%"
      :height="tableHeight"
    >
      <el-table-column
        v-if="showNumber"
        type="index"
        :label="numberLabel"
        width="60"
        border>
      </el-table-column>
      <template v-for="(column, index) in tableOpts">

        <!-- slot 添加自定义配置项 -->
        <!-- 内联表单处理 -->
        <el-table-column v-if="column.type === 'switch'"
                         :width="column.width || ''"
                         :label="column.label"
                         :key='column.label'>
          <template slot-scope="scope">
            <el-tooltip v-if="column.showHover" :content="column.handlercontent(scope.$index)" placement="top" effect="light">
              <el-switch
                v-model="scope.row[column.prop]"
                :active-value="column.active_value"
                :inactive-value="column.inactive_value"
                :active-text="column.active_text"
                :inactive-text="column.inactive_text"
                @change="column.handler(scope.$index)"
                >
              </el-switch>
            </el-tooltip>
            <el-switch
                v-else
                v-model="scope.row[column.prop]"
                :active-value="column.active_value"
                :inactive-value="column.inactive_value"
                :active-text="column.active_text"
                :inactive-text="column.inactive_text"
                @change="column.handler(scope.$index)"
                >
              </el-switch>
          </template>
        </el-table-column>
        <!-- 默认渲染列 -->
        <el-table-column v-else-if="column.slot" :label="column.label" :key="column.prop">
          <template  slot-scope="scope">
            <slot :name="column.slot" :msg="scope.row">
            </slot>
          </template>
        </el-table-column>
        <el-table-column v-else :prop="column.prop"
                         :key='column.label'
                         :label="column.label"
                         :align="column.align"
                         :width="column.width || ''"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <template v-if="!column.render">
              <template v-if="column.formatter">
                <span v-html="column.formatter(scope.row[column.prop])"></span>
              </template>
              <template v-else>
                <span>{{scope.row[column.prop]}}</span>
              </template>
            </template>
            <template v-else>
              <expand-dom :column="column" :row="scope.row" :render="column.render" :index="index"></expand-dom>
            </template>
          </template>
        </el-table-column>
      </template>
        <!-- 操作列处理      -->
      <el-table-column :fixed="fixed" :width="handleWidth || ''" v-if="openHandle" label="操作">
        <template slot-scope="scope" v-if="handleOpts">
          <slot name="btn_1" :msg="scope.row"></slot>
          <slot name="btn_2" :msg="scope.row"></slot>
          <slot name="btn_3" :msg="scope.row"></slot>
          <slot name="btn_4" :msg="scope.row"></slot>
          <el-button style="font-size: 12px" size="mini" v-if="handleOpts.edit" :type="handleOpts.edit.type" @click="handleEdit(scope.row)">{{handleOpts.edit.text}}</el-button>
          <el-button style="font-size: 12px" size="mini" v-if="handleOpts.other_1" :type="handleOpts.other_1.type" @click="handleOther1(scope.row)">{{handleOpts.other_1.text}}</el-button>
          <el-button style="font-size: 12px" size="mini" v-if="handleOpts.other_2" :type="handleOpts.other_2.type" @click="handleOther2(scope.row)">{{handleOpts.other_2.text}}</el-button>
          <el-button style="font-size: 12px" size="mini" v-if="handleOpts.other_3" :type="handleOpts.other_3.type" @click="handleOther3(scope.row)">{{handleOpts.other_3.text}}</el-button>
          <el-button style="font-size: 12px" size="mini" v-if="handleOpts.other_4" :type="handleOpts.other_4.type" @click="handleOther4(scope.row)">{{handleOpts.other_4.text}}</el-button>
          <el-button style="font-size: 12px" size="mini" v-if="handleOpts.other_5" :type="handleOpts.other_5.type" @click="handleOther5(scope.row)">{{handleOpts.other_5.text}}</el-button>
          <el-button style="font-size: 12px" size="mini" v-if="handleOpts.other_6" :type="handleOpts.other_6.type" @click="handleOther6(scope.row)">{{handleOpts.other_6.text}}</el-button>
          <el-button style="font-size: 12px;color: #F56C6C;" size="mini" v-if="handleOpts.delete" :type="handleOpts.delete.type" @click="handleDelete(scope.row)">{{handleOpts.delete.text}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 80px;"></div>
    <!-- 分页 -->
    <div v-if="showPage" :class=" currentSizePage <=10  ? 'page':   'page-shadow'   ">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </section>
</template>
<script>
import { dateformat } from '@utils/'
export default {
  data () {
    return {
      currentSizePage: null
    }
  },
  props: {
    randKey: Number,
    tableHeight: {
      type: String
      // default: '524px'
    },
    fixed: {
      type: [Boolean, String],
      default: false
    },
    tableOpts: {
      type: Array,
      require: true
    },
    data: {
      type: Array,
      default: () => []
    },
    openHandle: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 30, 50]
      }
    },
    handleOpts: {
      type: Object
    },
    handleWidth: [String, Number],
    showNumber: {
      type: Boolean,
      default: false
    },
    numberLabel: {
      type: String,
      default: '序号'
    },
    showPage: {
      type: Boolean,
      default: true
    }
  },
  components: {
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null
        }
      },
      render: (h, ctx) => {
        const params = {
          row: ctx.props.row,
          index: ctx.props.index
        }
        if (ctx.props.column) params.column = ctx.props.column
        return ctx.props.render(h, params)
      }
    }
  },
  methods: {
    handleSizeChange (val) {
      const _this = this
      _this.currentSizePage = val
      _this.$emit('handleSizeChange', val)
    },
    handleCurrentChange (val) {
      const _this = this
      _this.$emit('handleCurrentChange', val)
    },
    handleEdit (row) {
      const _this = this
      _this.$emit('edit', row)
      // console.log('click')
    },
    handleOther1 (row) {
      this.$emit('other_1', row)
    },
    handleOther2 (row) {
      this.$emit('other_2', row)
    },
    handleOther3 (row) {
      this.$emit('other_3', row)
    },
    handleOther4 (row) {
      this.$emit('other_4', row)
    },
    handleOther5 (row) {
      this.$emit('other_5', row)
    },
    handleOther6 (row) {
      this.$emit('other_6', row)
    },
    handleDelete (row) {
      this.$emit('delete', row)
    },
    normalDateFormat: dateformat,
    doLayout () {
      this.$refs.table.doLayout()
    }
  }
}
</script>

<style scoped lang="scss">
.page,.page-shadow {
  background: #fff;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 210px;
  z-index: 100;
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.page-shadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12), 0px -4px 4px rgba(0, 0, 0, 0.04)
}
.scope {
  color: red;
}

 ::v-deep .el-table th.el-table__cell>.cell {
  color: #909399;
  font-weight: 400;
  font-size: 14px;
 }
</style>
